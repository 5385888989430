.wrap {
  margin-top: 0rem;
}

.head {
  margin-top: 8rem;
}
.text {
  justify-content: center;
}

.head h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.head h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #7620ff 50%, #7620ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

.about-wrapper {
  border-bottom: 2px solid #172755;
  width: 80%;
  margin: 2rem auto;
  /* margin-top: 10rem; */
  padding-bottom: 3rem;
}

.heading-about {
  color: #172755;
  margin: 1rem auto;
}

.rule-about {
  border: 0.2rem solid #7620ff;
  margin-bottom: 1rem;
  background-color: #7620ff;
  font-family: "Poppins", sans-serif;
}

.about {
  font-size: 1.5rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: #6b6f7d;
  margin: auto;
  display: flex; 
  align-items: flex-start;
}

.about-image {
    margin-right: 2rem;
    border-radius: 2%;
}
.about-image-right {
  margin-right: 0;
  margin-left: 2rem;
}
.about-text{
  width:90%;
  font-size: 80%;
  text-align: justify;
}
  /* box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.3); */



@media(max-width: 768px){
  .about{
    display: flex;
    flex-direction: column;
  }
  .about-image{
    margin-right: 0;
  }
  .about-image-right {
    margin-left: 0;
    order: -1;
  }
}


/* img {
  width:50px;

} */
/* .about-image-right {
  width: 100%;
  margin-left: 2rem;
} */

/* .about-image-right img {
  width: 100%;
  border-radius: 5%;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.3);
}*/
